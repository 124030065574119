var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bg-account-pages vh-100 d-flex align-items-center bg-center position-relative",style:({
    'background-image': 'url(' + require('@/assets/images/auth-bg.png') + ')',
  })},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8 col-lg-6 col-xl-5"},[_c('div',{staticClass:"bg-white shadow"},[_c('div',{staticClass:"p-4"},[_c('div',{staticClass:"text-center mt-3"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"logo-dark",attrs:{"src":require("@/assets/images/logo-dark.png"),"alt":"","height":"30"}})]),_c('p',{staticClass:"text-muted mt-2"},[_vm._v("Sign in to continue to Qexal.")])],1),_c('div',{staticClass:"p-3"},[_c('form',{staticClass:"av-invalid",attrs:{"novalidate":"","action":"#","method":"get"}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"mt-4 mb-0 text-center"},[_c('router-link',{staticClass:"text-dark",attrs:{"to":"/password-forget"}},[_c('lock-icon',{staticClass:"icon-xs icon me-1"}),_vm._v(" Forgot your password?")],1)],1)])])])]),_c('div',{staticClass:"text-center mt-4"},[_c('p',[_vm._v(" Don't have an account ? "),_c('router-link',{staticClass:"font-weight-semibold text-primary",attrs:{"to":"/sign-up"}},[_vm._v(" Signup ")])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"username"}},[_vm._v("Username")]),_c('input',{staticClass:"form-control",attrs:{"name":"username","required":"","placeholder":"Enter username","id":"username","type":"text","value":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"userpassword"}},[_vm._v("Password")]),_c('input',{staticClass:"form-control",attrs:{"name":"password","required":"","placeholder":"Enter password","id":"userpassword","type":"password","value":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-check"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox","value":"","id":"remember-Check"}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"remember-Check"}},[_vm._v(" Remember me ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-grid mt-3"},[_c('button',{staticClass:"btn btn-primary btn-none btn-block",attrs:{"type":"button"}},[_vm._v(" Log In ")])])
}]

export { render, staticRenderFns }